<template>
  <div class="full-height__ container_auth">
    <div
      class="container_temp d-flex flex-column align-center justify-center pa-3"
    >
      <v-card class="temp__ d-flex" elevation="5">
        <div class="covers__ pa-3 d-flex flex-column align-center white--text">
          <div class="d-flex justify-center">
            <img
              src="../../assets/img/Tanoto logo-green.png"
              class="mr-2"
              alt="logo-tanoto-foundation"
              height="40px"
              style="z-index: 1"
            />
            <img
              src="../../assets/img/logo-teladan.png"
              alt="logo-teladan"
              height="40px"
              style="z-index: 1"
            />
          </div>
          <img
            src="../../assets/img/Aset-Ilustrasi Login.png"
            height="230px"
            alt=""
          />
          <div class="mt-n3 ml-n10 line-h">
            <p class="ma-0">Welcome to</p>
            <h1>Teladan</h1>
            <h2>Web report</h2>
          </div>
        </div>
        <v-card flat class="temp_form">
          <div class="pt-7 px-7">
            <h2 style="color: #006341">Login</h2>
            <div style="min-height: 50px; mb-2">
              <v-alert
                v-if="response"
                :value="response ? true : false"
                dense
                text
                transition="scale-transition"
                class="rounded_alert py-2 px-7"
                :type="response.status == 'failed' ? 'error' : 'success'"
              >
                <small>{{ response.message }}</small>
              </v-alert>
              <v-alert
                v-if="err"
                dense
                text
                cl
                class="rounded_alert py-2 px-7"
                type="error"
              >
                <small>{{ err }}</small>
              </v-alert>
            </div>
            <form @submit.prevent="login">
              <v-row>
                <v-col cols="2"
                  ><v-btn fab depressed small
                    ><v-icon small>mdi-email</v-icon></v-btn
                  ></v-col
                >
                <v-col cols="10">
                  <v-text-field
                    v-model="email"
                    hide-details="auto"
                    :error-messages="EmailError"
                    label="Email/Username"
                    required
                    dense
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"
                  ><v-btn fab depressed small
                    ><v-icon small>mdi-lock</v-icon></v-btn
                  ></v-col
                >
                <v-col cols="10">
                  <v-text-field
                    dense
                    v-model="password"
                    :error-messages="PasswordError"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    counter
                    @click:append="show = !show"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div class="d-flex justify-end mb-7">
                <v-btn
                  class="mb-3 text-capitalize"
                  type="login"
                  v-if="!loading"
                  color="#006341"
                  dark
                >
                  Login
                </v-btn>
                <v-btn
                  class="mb-3 text-capitalize"
                  v-if="loading"
                  color="#006341"
                  dark
                >
                  Loading..
                </v-btn>
              </div>
            </form>
          </div>
          <v-card-actions class="footer">
            <section class="p-1 d-flex justify-center full_width__">
              <small class="white--text"
                >Forgot your Password?
                <span
                  ><router-link class="white--text" to="/forgot-password"
                    >Click Here</router-link
                  ></span
                ></small
              >
            </section>
          </v-card-actions>
        </v-card>
      </v-card>
    </div>
    <div class="temp-boba d-flex">
      <div class="boba1"></div>
      <div class="boba2"></div>
      <div class="boba3"></div>
      <div class="boba4"></div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    email: { required },
    password: { required, minLength: minLength(8) },
  },
  computed: {
    ...mapState({
      errMsg: (state) => state.auth.errMsg,
      response: (state) => state.auth.response,
    }),
    EmailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email harus di isi!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      show: false,
      dialogResend: false,
      errAlert: false,
      loading: false,
      err: null,
    };
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    this.$store.commit("auth/RESET_RESPONSE");
    next();
  },
  mounted() {
    this.checkDevice()
    this.checkDate()
  },
  methods: {
    checkDevice() {
      let userAgent = detect.parse(window.navigator.userAgent);
      if (userAgent.device.type !== "Desktop") {
        this.$router.push("/access-denied"); // Access Ditutup, jika akses dilakukan dari selain desktop
      }
    },
    checkDate() {
     let date =  this.$date('2021-02').daysInMonth()
     console.log(date)
    },
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch("auth/login", {
            key: this.email,
            password: this.password,
          })
          .then((data) => {
            this.loading = false;
            if (data.code == 200) {
              this.$router.go("/");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.err = err.response.data.message;
          });
      }
    },
  },
};
</script>

<style scoped>
.container_auth {
  height: 100vh;
  width: 100%;
  background: #006341;
  overflow: hidden;
}
.container_temp {
  top: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 2 !important;
}
.temp__ {
  width: 45%;
  height: max-content;
  background: white;
  border-radius: 20px !important;
  overflow: hidden;
}
.temp_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 57%;
}
.covers__ {
  background: #a2aaad;
  width: 43%;
  border-radius: 0 !important;
}
.footer {
  background: #b3a369;
  border-radius: 0 !important;
}
.rounded_alert {
  border-radius: 20px !important;
}
.line-h {
  line-height: normal;
}
.temp-boba {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}

.boba1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 6s;
  transform-origin: bottom;
  animation-delay: 1s;
}
.boba2 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 7s;
  transform-origin: bottom;
  animation-delay: 2s;
}
.boba3 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 5s;
  transform-origin: bottom;
  animation-delay: 0.5s;
}
.boba4 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba4 infinite ease-in-out alternate 5s;
  transform-origin: bottom;
  animation-delay: 6s;
}

@keyframes boba1 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
@keyframes boba4 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(200%, 0%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
@media (max-width: 1282px) {
  .temp__ {
    width: 55%;
  }
}
@media (max-width: 1030px) {
  .temp__ {
    width: 65%;
  }
}
@media (max-width: 932px) {
  .temp__ {
    width: 70%;
  }
}
@media (max-width: 850px) {
  .temp__ {
    width: 80%;
  }
}
@media (max-width: 730px) {
  .temp__ {
    width: 90%;
  }
}
</style>
